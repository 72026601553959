import React from 'react';
import Layout from '../../components/Layout';
import Hero, { HeroWhyMyfembreeEMContent } from '../../components/Hero/Hero';
import QuickLinks2, {
  QuickLinks2WhyMyfembreeEMContent
} from '../../components/QuickLinks2/QuickLinks2';
import IconTextGrid, {
  whatIsMyfembreeEM
} from '../../components/IconTextGrid/IconTextGrid';
import CostSupport, {
  meaningfulReliefEM
} from '../../components/CostSupport/CostSupport';
import FloatingLinks, {
  WhyMyfembreeEMFloatingLinkData
} from '../../components/FloatingLinks/FloatingLinks';
import KnockoutDriver, {
  startConvoEMProps
} from '../../components/KnockoutDriver/KnockoutDriver';
import BenefitsEM from '../../components/BenefitsEM/BenefitsEM';

import Shield from '../../assets/svgs/Shield.svg';
import HowDoesMfbWork, {
  howDoesMfbWorkEMContent
} from '../../components/HowDoesMfbWork/HowDoesMfbWork';
import SideEffects, {
  sideEffectsEM
} from '../../components/SideEffects/SideEffects';

import WhyMyfembreeHero from '../../components/Hero/why-myfembree-hero';
import WhyMyFembreeSearching from '../../components/WhyMyFembree-Searching/whyMyfembreeSearching';
import Benefits from '../../components/Benefits/benefits';
import WhatCauses from '../../components/WhatCauses/what-causes';
import HowDoesMyfembreeWork from '../../components/HowDoesMyfembreeWork/how-does-myfembree-work';
import Supports from '../../components/Supports/supports';
import PossibleSideEffects from '../../components/PossibleSideEffects/possible-sidde-effects';
import { Ready, FindOut } from '../../components/WhyMFCtas/why-mf-ctas';
import ReducedImpact from '../../components/ReducedImpact/reduced-impact';
import { ImprovedEndoPain } from '../../components/ImprovedEndoPain/improved-endo-pain';

const Home = () => {
  const {
    refs: [headerRef, whatIsRef, benefitsRef, sideEffectsRef, howDoesTtRef],
    floatingLinksProps
  } = WhyMyfembreeEMFloatingLinkData();
  return (
    <Layout section="EM" page="/endometriosis/why-myfembree/">
      <>
        <WhyMyfembreeHero />

        <WhyMyFembreeSearching />

        <Benefits />

        <ReducedImpact />

        <ImprovedEndoPain />

        <WhatCauses />

        <HowDoesMyfembreeWork />

        {/* <Supports /> */}

        <PossibleSideEffects />

        <Ready />

        {/* <FindOut /> */}

        {/* <Hero {...{ ...HeroWhyMyfembreeEMContent, floatingRef: headerRef }}>
          <QuickLinks2 {...QuickLinks2WhyMyfembreeEMContent} />
        </Hero> */}
        {/* <FloatingLinks {...floatingLinksProps} /> */}
        {/* <CostSupport {...meaningfulReliefEM} />
        <a id="what-is-myfembree" className="relative -top-24 md:-top-44" />
        <IconTextGrid {...{ ...whatIsMyfembreeEM, floatingRef: whatIsRef }} />
        <BenefitsEM floatingRef={benefitsRef} />
        <SideEffects
          {...{
            ...sideEffectsEM,
            floatingRef: sideEffectsRef
          }}
        />
        <KnockoutDriver {...startConvoEMProps} />
        <HowDoesMfbWork
          {...{ ...howDoesMfbWorkEMContent, floatingRef: howDoesTtRef }}
        />
        <KnockoutDriver
          {...{
            icon: <Shield />,
            iconStroke: 'white',
            iconBgColor: 'dark-green',
            prompt:
              'There may be ways for you to save on the cost of Myfembree.',
            buttonProps: {
              label: 'FIND OUT HOW',
              ariaLabel: '',
              color: 'secondary-fuchsia',
              target: '_self',
              href: '/endometriosis/cost-and-support/',
              position: 'right'
            },
            color: 'fuchsia-100'
          }}
        /> */}
      </>
    </Layout>
  );
};

export const Head = () => {
  const canonicalURL = 'https://www.myfembree.com/endometriosis/why-myfembree/';
  const title =
    'Why MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) 0.4mg, 1mg, 0.5mg Tablets for Endometriosis Pain';
  const pageTitle =
    'Looking for relief from endometriosis pain? MYFEMBREE® helps relieve moderate to severe endometriosis pain in premenopausal women. See safety info & BOXED WARNING.';
  const description =
    'Looking for relief from endometriosis pain? MYFEMBREE® helps relieve moderate to severe endometriosis pain in premenopausal women. See safety info & BOXED WARNING.';
  return (
    <>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="title" content={pageTitle} />
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalURL} />
      <meta name="url" content={canonicalURL} />
      <html lang="en" />
    </>
  );
};

export default Home;

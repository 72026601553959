import React from 'react';

import SittingIcon from '../../assets/images/sitting-icon.svg';
import SleepingIcon from '../../assets/images/sleeping-icon.svg';
import ParticipatingIcon from '../../assets/images/participating-icon.svg';
import CopingIcon from '../../assets/images/coping-icon.svg';

const list = [
  {
    text: 'Sitting, standing, walking, or exercising',
    img: SittingIcon,
    imgWidth: 63,
    imgHeight: 80,
    alt: 'Exercising icon'
  },
  {
    text: 'Sleeping',
    img: SleepingIcon,
    imgWidth: 83,
    imgHeight: 84,
    alt: 'Sleep icon'
  },
  {
    text: 'Participating in leisure activities',
    img: ParticipatingIcon,
    imgWidth: 91,
    imgHeight: 84,
    alt: 'Leisure activity icon'
  },
  {
    text: 'Coping with pain',
    img: CopingIcon,
    imgWidth: 106,
    imgHeight: 58,
    alt: 'Coping with pain icon'
  }
];

function ReducedImpact() {
  return (
    <section>
      <div className="container mx-auto">
        <div className="reduced-impact flex flex-col justify-center items-center text-white-100">
          <h2 className="font-mulish font-bold text-center">
            Myfembree reduced the overall impact endo pain had on daily
            activities such as:
          </h2>

          <ul className="flex flex-wrap justify-between mx-auto">
            {list.map((item, index) => {
              return (
                <li
                  key={index}
                  className={`flex flex-col items-center ${
                    index === 3 && 'self-center'
                  }`}
                >
                  <img
                    src={item.img}
                    width={item.imgWidth}
                    height={item.imgHeight}
                    alt={item.alt}
                    loading="lazy"
                  />

                  <p className="text-center font-semibold">{item.text}</p>
                </li>
              );
            })}
          </ul>

          <p className="text-center">
            In studies, women were asked how often their endo pain made them
            unable to engage in daily activities in the last 4 weeks (e.g.,
            attend a social event, do jobs around the house, sitting,
            exercising, sleeping).
          </p>
        </div>
      </div>
    </section>
  );
}

export default ReducedImpact;
